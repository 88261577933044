import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TopNav from "../components/TopNav";
import SideNav from "../components/SideNav";
import Footer from "../components/footer";
import ResourcePic from "../img/resource.png";
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Document, Page, pdfjs } from "react-pdf";
import cartImage from "../img/Cartridge.png";
// import pdfUrl from "../img/SOP.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function ResourcesPage() {
  const videoId = "2su2D2paZnI";
  const [activeTab, setActiveTab] = useState("1");
  const [numPages, setNumPages] = useState(undefined);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => { });
  const pdfUrl =
    "https://roshanwater.com/wp-content/uploads/2023/07/SOP-Version2.pdf";
  // const pdfUrl = "https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Resources</title>
      </Helmet>
      <TopNav imageSrc={ResourcePic} text="Resources" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div id="accordion">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <div className="d-sm-flex align-items-center d-flex">
                  <div className="flex-grow-1">
                    <h6 className="m-0 font-weight-bold text-gray-800">
                      Resources
                    </h6>
                  </div>
                </div>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => setActiveTab("1")}
                    active={activeTab === "1" ? true : false}
                  >
                    Tutorial video
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => setActiveTab("2")}
                    active={activeTab === "2" ? true : false}
                  >
                    SOP
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => setActiveTab("3")}
                    active={activeTab === "3" ? true : false}
                    href="#"
                  >
                    Filling the cartridges
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => setActiveTab("4")}
                    active={activeTab === "4" ? true : false}
                    href="#"
                  >
                    Feedback form
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="center-frame">
                        <iframe
                          width="800"
                          height="400"
                          src={`https://www.youtube.com/embed/${videoId}`}
                          title="YouTube Video Player"
                          allowFullScreen
                        />
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="pdf-container">
                        <Document
                          file={pdfUrl}
                          // onLoad={getPdf}
                          onLoadSuccess={onDocumentLoadSuccess}
                          className="pdf-document"
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                        {numPages !== undefined && (
                          <div className="pdf-nav">
                            <Button
                              onClick={() =>
                                setPageNumber(
                                  pageNumber <= 1 ? 1 : pageNumber - 1
                                )
                              }
                            >
                              {"<"}
                            </Button>
                            Page {pageNumber} of {numPages}
                            <Button
                              onClick={() =>
                                setPageNumber(
                                  pageNumber >= numPages
                                    ? numPages
                                    : pageNumber + 1
                                )
                              }
                            >
                              {">"}
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="cartridge-wrapper">
                        <img
                          src={cartImage}
                          alt="cartridge"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
              {/* {isLoading ? (
                <ShabiBelovedLoader />
              ) : (
                <BottleList currentPage={currentPage} data={data} />
              )} */}
            </div>
          </div>

          <a className="scroll-to-top rounded " href="#page-top ">
            <i className="fas fa-angle-up "></i>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResourcesPage;
