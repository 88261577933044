import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Helmet } from "react-helmet";
import { useQuery } from 'react-query';
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import ListPage from '../components/ListPage';
import { GetSamples, GetDropDown } from '../services';
import { EntityList } from '../components/EntityList';
import { renderDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';
import plasticbottle from '../img/plasticbottle.png';

const SampleList = ({ data, onSort, onFilter, sortField, sortAscending, filters, filterOptions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const columns = [
    { field: 'sampleId', header: 'ID', sortable: true},
    { field: 'collectionsSite', header: 'Collection Site', sortable: false },
    { field: 'waterType', header: 'Water Type', sortable: false},
    { field: 'residualChlorine', header: 'Residual Chlorine', sortable: false},
    { 
      field: 'userFullName', 
      header: 'User', 
      render: (value, row) => generateLink('user', row.userId, row.userFullName, 'fa-user'),
      sortable: false,
    },
    { 
      field: 'company.name', 
      header: 'Company', 
      render: (value, row) => generateLink('company', row.company.companyId, row.company.name, 'fa-building'),
      sortable: false,
    },
    {
      field: 'setDateTimeUtc',
      header: 'Date & Time',
      render: renderDateTime,
      sortable: false,
    },
  ];

  const handleDetails = (sampleId) => {
    navigate(`/samples/${sampleId}?${searchParams.toString()}`);
  };

  const actions = [
    { label: 'Details', onClick: handleDetails, color: 'primary' },
  ];

  return (
    <EntityList
      entityName="samples"
      entities={data?.samples || []}
      columns={columns}
      actions={actions}
      onSort={onSort}
      onFilter={onFilter}
      sortField={sortField}
      sortAscending={sortAscending}
      filters={filters}
      filterOptions={filterOptions}
    />
  );
};

const SampleListView = () => {
  const navigate = useNavigate();
  const { data: companies } = useQuery("getCompanies", GetDropDown);
  const [searchParams] = useSearchParams();

  const handleGenerateQRCode = async () => {
    navigate(`/samples/generate-qr?${searchParams.toString()}`);
  };

  const filterOptions = [
    { field: 'sampleId', label: 'Sample ID', type: 'text' },
    { field: 'CompanyId', label: 'Company', type: 'select', options: 
      companies ? Object.entries(companies).map(([value, label]) => ({ value, label })) : []
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Sample Bottles</title>
      </Helmet>
      <TopNav imageSrc={plasticbottle} text="Sample Bottles" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3 mb-0 text-gray-800">Sample Bottles</h1>
            <Button color="primary" onClick={handleGenerateQRCode}>
              <i className="fa-solid fa-qrcode fa-sm text-white-50 mr-2"></i>
              Generate QR Code
            </Button>
          </div>
          <ListPage
            fetchDataFunction={GetSamples}
            ListComponent={SampleList}
            filterOptions={filterOptions}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SampleListView;