import React from "react";

const RoshanLoader = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="loadingio-spinner-spinner-9bu8c120xat">
        <div className="ldio-2xi5lb84zo6">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default RoshanLoader;
