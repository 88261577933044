import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Helmet } from "react-helmet";
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import ListPage from '../components/ListPage';
import { GetCompanies } from '../services';
import { EntityList } from '../components/EntityList';
import { renderDateTime } from '../util/convertDate';
import company from '../img/company.png';

const CompanyList = ({ data, onSort, onFilter, sortField, sortAscending, filters, filterOptions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const columns = [
    { field: 'name', header: 'Company Name', sortable: false},
    {
      field: 'createDateTime',
      header: 'Create Date',
      render: renderDateTime,
      sortable: false
    },
    {
      field: 'isActive',
      header: 'Status',
      render: (value) => value ? 'Active' : 'Inactive',
      sortable: false 
    },
  ];

  const handleDetails = (companyId) => {
    navigate(`/companies/${companyId}?${searchParams.toString()}`);
  };

  const actions = [
    { label: 'Details', onClick: handleDetails, color: 'primary' },
  ];

  return (
    <EntityList
      entityName="companies"
      entities={data?.companies || []}
      columns={columns}
      actions={actions}
      onSort={onSort}
      onFilter={onFilter}
      sortField={sortField}
      sortAscending={sortAscending}
      filters={filters}
      filterOptions={filterOptions}
    />
  );
};

const CompanyListView = () => {
  const navigate = useNavigate();

  const handleCreateCompany = () => {
    navigate('/companies/create');
  };

  const filterOptions = [
    { field: 'name', label: 'Company Name', type: 'text' },
   
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Companies</title>
      </Helmet>
      <TopNav imageSrc={company} text="Companies" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3 mb-0 text-gray-800">Companies</h1>
            <Button color="primary" onClick={handleCreateCompany}>
              <i className="fa-solid fa-building fa-sm text-white-50 mr-2"></i>
              Add new company
            </Button>
          </div>
          <ListPage
            fetchDataFunction={GetCompanies}
            ListComponent={CompanyList}
            filterOptions={filterOptions}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompanyListView;