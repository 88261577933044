import React from "react";
import { Navigate } from "react-router-dom";
import useLocalStorage from "./localStorage";

export const RequireAuth = ({ children }) => {
  const [localToken, setLocalToken] = useLocalStorage("localToken", "");
  if (!localToken || localToken.length < 1) {
    return <Navigate to="/login" />;
  }
  return children;
};
