import React from 'react';
import { Link } from 'react-router-dom';

export const generateLink = (type, id, displayText, icon = null) => {
  const routes = {
    company: '/companies',
    device: '/devices',
    user: '/users',
    testRecord: '/test-records',
    sample: '/samples',
  };

  const route = routes[type];
  if (!route) {
    console.warn(`Unknown link type: ${type}`);
    return displayText;
  }

  return (
    <Link to={`${route}/${id}`} className="d-inline-flex align-items-center">
      {icon && <i className={`fas ${icon} mr-1`}></i>}
      {displayText}
    </Link>
  );
};