import React, { useState } from "react";
// import { useQuery } from "react-query";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from "reactstrap";
import useLocalStorage from "./hoc/localStorage";
import { useNavigate } from "react-router-dom";

const TopNav = ({
  fullName,
  imageSrc,
  imageWidth = 35,
  imageHeight = 35,
  text,
}) => {
  const [localToken, setLocalToken] = useLocalStorage("localToken", "");
  const [localFullName, setLocalFullName] = useLocalStorage("fullName", "");
  const [localRole, setLocalRole] = useLocalStorage("role", "");

  const [isOpen, setIsOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setLocalFullName("");
    setLocalRole("");
    setLocalToken("");
    window.location.assign("/login");
  };

  return (
    <div id="content-wrapper" className="d-flex flex-column maintopbar mb-4">
      <div id="content">
        <Navbar light color="light" className="  mb-4  shadow">
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-md-none rounded-circle mr-3"
            onClick={() => setIsOpen(!isOpen)}
          >
            <i className="fa fa-bars"></i>
          </button>
          <div className="d-sm-flex align-items-center flex-grow-1 d-none d-sm-block">
            <div className="d-sm-none d-md-block">
              {/* <img
                src={imageSrc}
                alt={text}
                width={imageWidth}
                height={imageHeight}
              /> */}
            </div>
            <div className="d-sm-none d-md-block">
              <h3 className="text-gray-800 ml-1 mb-0 "> {text}</h3>
            </div>
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="d-flex flex-column">
              <NavItem>
                <NavLink href="/dashboard/">Dashboard</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/test-records">Test Records</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/devices">Devices</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/samples">Samples</NavLink>
              </NavItem>
              {localRole === "Administrator" && (
                <>
                  <NavItem>
                    <NavLink href="/companies">Companies</NavLink>
                  </NavItem>
                </>
              )}
              <NavItem>
                <NavLink href="/users-list">User List</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink href="/profile">Profile</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/settings">Settings</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={handleClick} href="/login">
                  Logout
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
          <ul className="navbar-nav topbar ml-auto">
            <li className="nav-item dropdown no-arrow mx-1">
              <a
                className="nav-link dropdown-toggle border-0"
                id="alertsDropdown"
                role={"button"}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-bell fa-fw"></i>
                <span className="badge badge-danger badge-counter">0</span>
              </a>
              <div
                className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="alertsDropdown"
              >
                <h6 className="dropdown-header">Alerts Center</h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-primary">
                      <i className="fas fa-file-alt text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 12, 2019</div>
                    <span className="font-weight-bold">
                      A new monthly report is ready to download!
                    </span>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-success">
                      <i className="fas fa-donate text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 7, 2019</div>
                    $290.29 has been deposited into your account!
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="mr-3">
                    <div className="icon-circle bg-warning">
                      <i className="fas fa-exclamation-triangle text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 2, 2019</div>
                    Spending Alert: We've noticed unusually high spending for
                    your account.
                  </div>
                </a>
                <a
                  className="dropdown-item text-center small text-gray-500"
                  href="#"
                >
                  Show All Alerts
                </a>
              </div>
            </li>
            <li className="nav-item dropdown no-arrow mx-1">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="messagesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-envelope fa-fw"></i>
                <span className="badge badge-danger badge-counter">0</span>
              </a>
              <div
                className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messagesDropdown"
              >
                <h6 className="dropdown-header">Message Center</h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src="../img/undraw_profile_1.svg"
                      alt="..."
                    />
                    <div className="status-indicator bg-success"></div>
                  </div>
                  <div className="font-weight-bold">
                    <div className="text-truncate">
                      Hi there! I am wondering if you can help me with a problem
                      I've been having.
                    </div>
                    <div className="small text-gray-500">
                      Emily Fowler · 58m
                    </div>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src="../img/undraw_profile_2.svg"
                      alt="..."
                    />
                    <div className="status-indicator"></div>
                  </div>
                  <div>
                    <div className="text-truncate">
                      I have the photos that you ordered last month, how would
                      you like them sent to you?
                    </div>
                    <div className="small text-gray-500">Jae Chun · 1d</div>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src="../img/undraw_profile_3.svg"
                      alt="..."
                    />
                    <div className="status-indicator bg-warning"></div>
                  </div>
                  <div>
                    <div className="text-truncate">
                      Last month's report looks great, I am very happy with the
                      progress so far, keep up the good work!
                    </div>
                    <div className="small text-gray-500">
                      Morgan Alvarez · 2d
                    </div>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                      alt="..."
                    />
                    <div className="status-indicator bg-success"></div>
                  </div>
                  <div>
                    <div className="text-truncate">
                      Am I a good boy? The reason I ask is because someone told
                      me that people say this to all dogs, even if they aren't
                      good...
                    </div>
                    <div className="small text-gray-500">
                      Chicken the Dog · 2w
                    </div>
                  </div>
                </a>
                <a
                  className="dropdown-item text-center small text-gray-500"
                  href="#"
                >
                  Read More Messages
                </a>
              </div>
            </li>
            <div className="topbar-divider  d-sm-block"></div>
            <div id="tog" className=" nav-link d-flex align-self-center">
              <Dropdown
                isOpen={isToggled}
                direction="down"
                toggle={function noRefCheck() {
                  setIsToggled(!isToggled);
                }}
              >
                <DropdownToggle
                  className="mr-2  d-lg-inline text-gray-600 small"
                  tag="span"
                >
                  {/* <span className="mr-2 d-none d-lg-inline text-gray-600 small"> */}
                  {!localFullName || localFullName === ""
                    ? fullName
                    : localFullName}
                  {/* </span> */}
                  <img
                    className="img-profile rounded-circle"
                    src="../img/undraw_profile_2.svg"
                    alt=""
                  />
                </DropdownToggle>
                <DropdownMenu container={"tog"}>
                  <DropdownItem onClick={() => navigate("/profile")}>
                    Profile
                  </DropdownItem>
                  {/* <DropdownItem onClick={() => navigate("/settings")}>
                    Setting
                  </DropdownItem> */}
                  <DropdownItem onClick={handleClick}>Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </ul>
        </Navbar>
      </div>
    </div>
  );
};

export default TopNav;
