import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

function MapProvider({ lat, lng, observations }) {
  return (
    <MapContainer center={[lat, lng]} zoom={15} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[lat, lng]}>
        <Popup>{observations}</Popup>
      </Marker>
    </MapContainer>
  );
}

export default MapProvider;
