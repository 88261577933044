import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Helmet } from "react-helmet";
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import ListPage from '../components/ListPage';
import { GetDevices, GetDropDown } from '../services';
import { EntityList } from '../components/EntityList';
import { renderDateTime } from '../util/convertDate';
import monitor from '../img/monitor.png';
import { generateLink } from '../util/linkHelper';
import { useQuery } from 'react-query';

const DeviceList = ({ data, onSort, onFilter, sortField, sortAscending, filters, filterOptions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const columns = [
    { field: 'name', header: 'Name', sortable: true, filterable: true },
    { field: 'deviceToken', header: 'Device Token', filterable: true },
    { 
      field: 'company.name', 
      header: 'Company', 
      render: (value, row) => generateLink('company', row.company.companyId, row.company.name, 'fa-building'),
      sortable: true 
    },
    { field: 'status', header: 'Status' },
    {
      field: 'lastSyncDateTime',
      header: 'Last Sync',
      render: renderDateTime,
      sortable: false
    },
  ];

  const handleDetails = (deviceId) => {
    navigate(`/devices/${deviceId}?${searchParams.toString()}`);
  };

  const handleEdit = (deviceId) => {
    navigate(`/devices/${deviceId}/edit?${searchParams.toString()}`);
  };

  const actions = [
    { label: 'Edit', onClick: handleEdit, color: 'primary' },
    { label: 'Details', onClick: handleDetails, color: 'info' },
  ];

  return (
    <EntityList
      entityName="devices"
      entities={data?.devices || []}
      columns={columns}
      actions={actions}
      onSort={onSort}
      onFilter={onFilter}
      sortField={sortField}
      sortAscending={sortAscending}
      filters={filters}
      filterOptions={filterOptions}
    />
  );
};

const DeviceListView = () => {
  const navigate = useNavigate();
  const { data: companies } = useQuery("getCompanies", GetDropDown);

  const handleCreateDevice = () => {
    navigate('/devices/create');
  };

  const filterOptions = [
    { field: 'name', label: 'Device Name', type: 'text' },
    { field: 'deviceToken', label: 'Device Token', type: 'text' },
    { field: 'CompanyId', label: 'Company', type: 'select', options: 
      companies ? Object.entries(companies).map(([value, label]) => ({ value, label })) : []
    },
    { field: 'status', label: 'Status', type: 'select', options: [
      { value: 'NEW', label: 'New' },
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
    ]}
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Devices</title>
      </Helmet>
      <TopNav imageSrc={monitor} text="Devices" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3 mb-0 text-gray-800">Devices</h1>
            <Button color="primary" onClick={handleCreateDevice}>
              <i className="fa-solid fa-desktop fa-sm text-white-50 mr-2"></i>
              Create New Device
            </Button>
          </div>
          <ListPage
            fetchDataFunction={GetDevices}
            ListComponent={DeviceList}
            filterOptions={filterOptions}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DeviceListView;