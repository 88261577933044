import React from 'react';
import { GetCompany } from '../services';
import DetailPage from '../components/DetailPage';
import { Card, CardBody, Row, Col } from 'reactstrap';
import company from '../img/company.png';
import { formatCompactDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';

const CompanyDetailsPage = () => {
  const renderDetailItem = (icon, label, value) => (
    <div className="mb-3">
      <i className={`fas ${icon} mr-2`}></i>
      <strong>{label}:</strong> {value || '-'}
    </div>
  );

  const renderSubSection = (title, items, linkType) => {
    if (!items || items.length === 0) return null;

    return (
      <Card className="mt-4">
        <CardBody>
          <h6 className="font-weight-bold mb-3">{title}</h6>
          {items.map((item, index) => (
            <div key={index} className="mb-2">
              <i className={`fas fa-${linkType === 'user' ? 'user' : 'desktop'} mr-2`}></i>
              {generateLink(linkType, item.id, item.name || item.fullName)}
            </div>
          ))}
        </CardBody>
      </Card>
    );
  };

  const CompanyDetailsList = ({ data }) => (
    <>
      <Card>
        <CardBody>
          <h6 className="font-weight-bold mb-3">Details</h6>
          <Row>
            <Col md={4}>
              {renderDetailItem('fa-building', 'Company Name', data.name)}
            </Col>
            <Col md={4}>
              {renderDetailItem('fa-toggle-on', 'Is Active', data.isActive ? 'Yes' : 'No')}
            </Col>
            <Col md={4}>
              {renderDetailItem('fa-trash', 'Is Deleted', data.isDeleted ? 'Yes' : 'No')}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {renderDetailItem('fa-calendar-plus', 'Created', formatCompactDateTime(data.createDateTime))}
            </Col>
            <Col md={4}>
              {renderDetailItem('fa-calendar-check', 'Updated', formatCompactDateTime(data.updateDateTime))}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {renderDetailItem('fa-map-marker-alt', 'Company Address', data.location)}
            </Col>
            <Col md={6}>
              {renderDetailItem('fa-comment', 'Comment', data.comment)}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {renderSubSection('Users', data.userList, 'user')}
      {renderSubSection('Devices', data.devices, 'device')}
    </>
  );

  return (
    <DetailPage
      title="Company Details"
      fetchDataFunction={GetCompany}
      DetailComponent={CompanyDetailsList}
      backUrl="/companies"
      iconSrc={company}
    />
  );
};

export default CompanyDetailsPage;