import React, { useState, useEffect } from 'react';
import { useParams, useNavigate ,useSearchParams} from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { GetDevice, CreateDevices, UpdateDevices, GetDropDown, GetTimeZone } from '../../services';
import TopNav from '../TopNav';
import SideNav from '../SideNav';
import Footer from '../footer';
import monitor from '../../img/monitor.png';

const DeviceForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isEditMode = !!id;
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    name: '',
    deviceToken: '',
    companyId: '',
    timeZone: '',
  });

  const { data: device } = useQuery(['getDevice', id], () => GetDevice(id), {
    enabled: isEditMode,
  });

  const { data: companies } = useQuery('getCompanies', GetDropDown);
  const { data: timeZones } = useQuery('getTimeZones', GetTimeZone);

  useEffect(() => {
    if (isEditMode && device) {
      setFormData({
        name: device.name,
        deviceToken: device.deviceToken,
        companyId: device.company?.companyId,
        timeZone: device.timeZone,
      });
    }
  }, [isEditMode, device]);

  const mutation = useMutation(isEditMode ? UpdateDevices : CreateDevices, {
    onSuccess: () => {
      queryClient.invalidateQueries('getDevices');
      navigate('/devices?'+searchParams.toString());
    },
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = isEditMode ? { ...formData, deviceId: id } : formData;
    mutation.mutate(payload);
  };

  return (
    <>
      <TopNav imageSrc={monitor} text={isEditMode ? 'Edit Device' : 'Create Device'} />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <h1 className="h3 mb-4 text-gray-800">{isEditMode ? 'Edit Device' : 'Create New Device'}</h1>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Device Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="deviceToken">Device Token</Label>
              <Input
                type="text"
                name="deviceToken"
                id="deviceToken"
                value={formData.deviceToken}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="companyId">Company</Label>
              <Input
                type="select"
                name="companyId"
                id="companyId"
                value={formData.companyId}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Company</option>
                {companies && Object.entries(companies).map(([id, name]) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="timeZone">Time Zone</Label>
              <Input
                type="select"
                name="timeZone"
                id="timeZone"
                value={formData.timeZone}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Time Zone</option>
                {timeZones && Object.entries(timeZones).map(([id, name]) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </Input>
            </FormGroup>
            <Button type="submit" color="primary">
              {isEditMode ? 'Update Device' : 'Create Device'}
            </Button>
            <Button type="button" color="secondary" className="ml-2" onClick={() => navigate('/devices?'+searchParams.toString())}>
              Cancel
            </Button>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DeviceForm;