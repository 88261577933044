import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { Helmet } from "react-helmet";
import TopNav from '../TopNav';
import SideNav from '../SideNav';
import Footer from '../footer';
import FormBuilder from '../FormBuilder';
import { GetDropDown, GenerateBottleBarCode } from '../../services';
import plasticbottle from '../../img/plasticbottle.png';

const GenerateQrForm = () => {
  const navigate = useNavigate();

  const { data: companies } = useQuery('getCompanies', GetDropDown);

  const mutation = useMutation(
    (formData) => GenerateBottleBarCode(formData.companyId, formData.count),
    {
      onSuccess: () => {
        navigate('/samples');
      },
    }
  );

  const fields = [
    { name: 'companyId', label: 'Company', type: 'select', options: companies, required: true },
    { name: 'count', label: 'Number of QR Codes', type: 'number', required: true, min: 1 },
  ];

  const handleSubmit = (formData) => {
    mutation.mutate(formData);
  };

  const handleCancel = () => navigate('/samples');

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Generate QR Codes</title>
      </Helmet>
      <TopNav imageSrc={plasticbottle} text="Generate QR Codes" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <h1 className="h3 mb-4 text-gray-800">Generate QR Codes</h1>
          <FormBuilder
            fields={fields}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel="Generate QR Codes"
            cancelLabel="Cancel"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GenerateQrForm;