import React from 'react';
import { GetDevice } from '../services';
import DetailsList from '../components/DetailsList';
import DetailPage from '../components/DetailPage';
import monitor from '../img/monitor.png';
import { formatCompactDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';

const DeviceDetailsPage = () => {
  const fields = [
    [
      { key: 'name', label: 'Device Name', icon: 'fa-desktop' },
      { key: 'status', label: 'Status', icon: 'fa-info-circle' },
      { key: 'deviceId', label: 'Device ID', icon: 'fa-id-badge' },
    ],
    [
      { 
        key: 'company.name', 
        label: 'Company', 
        icon: 'fa-building',
        render: (value, row) => generateLink('company', row.company.companyId, value, 'fa-building')
      },
      { key: 'deviceToken', label: 'Device Token', icon: 'fa-key' },
      { key: 'timeZone', label: 'Time Zone', icon: 'fa-globe' },
    ],
    [
      { 
        key: 'lastSyncDateTime', 
        label: 'Last Sync', 
        icon: 'fa-sync',
        render: (value) => value ? formatCompactDateTime(value) : '-'
      },
      { key: 'parameter', label: 'Device Parameter', icon: 'fa-sliders-h' },
    ],
    [
      { key: 'location', label: 'Location', icon: 'fa-map-marker-alt' },
      { key: 'comment', label: 'Comment', icon: 'fa-comment' },
    ],
  ];

  const DeviceDetailsList = ({ data }) => <DetailsList data={data} fields={fields} />;

  return (
    <DetailPage
      title="Device Details"
      fetchDataFunction={GetDevice}
      DetailComponent={DeviceDetailsList}
      backUrl="/devices"
      iconSrc={monitor}
    />
  );
};

export default DeviceDetailsPage;