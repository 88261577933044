import React from 'react';

const ConvertTime = (d) => {
  let date = new Date(d);
  return (
    (date.getHours().toString().length < 2
      ? "0" + date.getHours()
      : date.getHours()) +
    ":" +
    (date.getMinutes().toString().length < 2
      ? "0" + date.getMinutes()
      : date.getMinutes())
  );
};

const ConvertDate = (d) => {
  let date = new Date(d);
  return (
    date.getFullYear() +
    "-" +
    ((date.getMonth() + 1).toString().length < 2
      ? "0" + (date.getMonth() + 1)
      : (date.getMonth() + 1)) +
    "-" +
    (date.getDate().toString().length < 2 ? "0" + date.getDate() : date.getDate())
  );
};

const Time = (d) => {
  if (d !== null && d !== undefined) {
    let date = d.toString().split("T");
    let time = date[1].split(".");
    return time[0];
  }
};

const formatTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
};

const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const formatCompactDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
  return date.toLocaleString('en-US', options).replace(',', '');
};

const formatFullDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  return date.toLocaleString('en-US', options).replace(',', '');
};

const renderDateTime = (value) => {
  if (!value) return '-';
  const compactDateTime = formatCompactDateTime(value);
  const fullDateTime = formatFullDateTime(value);
  return (
    <span title={fullDateTime} style={{ cursor: 'help' }}>
      {compactDateTime}
    </span>
  );
};

export {
  ConvertTime,
  ConvertDate,
  Time,
  formatTime,
  formatDate,
  formatCompactDateTime,
  formatFullDateTime,
  renderDateTime
};