import React from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

const FormBuilder = ({ fields, onSubmit, onCancel, submitLabel, cancelLabel, initialValues = {} }) => {
  const [formData, setFormData] = React.useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {fields.map((field) => (
        <FormGroup key={field.name}>
          <Label for={field.name}>{field.label}</Label>
          {field.type === 'select' ? (
            <Input
              type="select"
              name={field.name}
              id={field.name}
              value={formData[field.name] || ''}
              onChange={handleInputChange}
              required={field.required}
            >
              <option value="">Select {field.label}</option>
              {field.options && Object.entries(field.options).map(([key, value]) => (
                <option key={key} value={key}>{value}</option>
              ))}
            </Input>
          ) : field.type === 'checkbox' ? (
            <Input
              type="checkbox"
              name={field.name}
              id={field.name}
              checked={formData[field.name] || false}
              onChange={handleInputChange}
            />
          ) : (
            <Input
              type={field.type || 'text'}
              name={field.name}
              id={field.name}
              value={formData[field.name] || ''}
              onChange={handleInputChange}
              required={field.required}
            />
          )}
        </FormGroup>
      ))}
      <Button type="submit" color="primary">
        {submitLabel || 'Submit'}
      </Button>
      {onCancel && (
        <Button type="button" color="secondary" onClick={onCancel} className="ml-2">
          {cancelLabel || 'Cancel'}
        </Button>
      )}
    </Form>
  );
};

export default FormBuilder;