import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetProfile, UpdateProfile, UpdateSetting, GenerateMobileToken } from '../services';

export const fetchProfile = createAsyncThunk(
    'profile/fetchProfile',
    async () => {
        const response = await GetProfile();
        return response;
    }
);

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async (profileData) => {
        const response = await UpdateProfile(profileData);
        return response;
    }
);

export const updateSetting = createAsyncThunk(
    'profile/updateSetting',
    async (settingData) => {
        const response = await UpdateSetting(settingData);
        return response;
    }
);

export const generateMobileToken = createAsyncThunk(
    'profile/generateMobileToken',
    async () => {
        const response = await GenerateMobileToken();
        return response;
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.data = { ...state.data, ...action.payload };
            })
            .addCase(updateSetting.fulfilled, (state, action) => {
                state.data = { ...state.data, ...action.payload };
            })
            .addCase(generateMobileToken.fulfilled, (state, action) => {
                state.data.mobileToken = action.payload;
            });
    },
});

export default profileSlice.reducer;