import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,useSearchParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Helmet } from "react-helmet";
import TopNav from '../TopNav';
import SideNav from '../SideNav';
import Footer from '../footer';
import FormBuilder from '../FormBuilder';
import { GetUser, RegisterUser, UpdateUser, GetCompanies, GetDropDown } from '../../services';
import users from '../../img/users.png';

const UserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isEditMode = !!id;
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState({});

  const { data: user } = useQuery(['getUser', id], () => GetUser(id), {
    enabled: isEditMode,
    onSuccess: (data) => {
      setInitialValues({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        companyId: data.company?.companyId,
        isManager: data.isManager
      });
    }
  });

  const { data: companies } = useQuery('getCompanies', GetDropDown);

  const mutation = useMutation(isEditMode ? UpdateUser : RegisterUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers');
      navigate('/users-list?'+searchParams.toString());
    },
  });

  const fields = [
    { name: 'firstName', label: 'First Name', type: 'text', required: true },
    { name: 'lastName', label: 'Last Name', type: 'text', required: true },
    { name: 'email', label: 'Email', type: 'email', required: true },
    { name: 'companyId', label: 'Company', type: 'select', options: companies, required: true },
    { name: 'isManager', label: 'Is Manager', type: 'checkbox' },
  ];

  if (!isEditMode) {
    fields.push({ name: 'password', label: 'Password', type: 'password', required: true });
  }

  const handleSubmit = (formData) => {
    const payload = isEditMode ? { ...formData, userId: id } : formData;
    mutation.mutate(payload);
  };

  const handleCancel = () => navigate('/users-list?'+searchParams.toString());

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | {isEditMode ? 'Edit' : 'Create'} User</title>
      </Helmet>
      <TopNav imageSrc={users} text={isEditMode ? 'Edit User' : 'Create User'} />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <h1 className="h3 mb-4 text-gray-800">{isEditMode ? 'Edit' : 'Create'} User</h1>
          <FormBuilder
            fields={fields}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel={isEditMode ? 'Update User' : 'Create User'}
            cancelLabel="Cancel"
            initialValues={initialValues}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserForm;