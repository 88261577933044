import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Helmet } from "react-helmet";
import TopNav from '../TopNav';
import SideNav from '../SideNav';
import Footer from '../footer';
import FormBuilder from '../FormBuilder';
import { GetCompany, CreateCompany, UpdateCompany } from '../../services';
import company from '../../img/company.png';

const CompanyForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isEditMode = !!id;

  const [initialValues, setInitialValues] = useState({});

  const { data: companyData } = useQuery(['getCompany', id], () => GetCompany(id), {
    enabled: isEditMode,
    onSuccess: (data) => {
      setInitialValues({
        name: data.name,
        isActive: data.isActive,
        comment: data.comment,
        location: data.location,
      });
    }
  });

  const mutation = useMutation(isEditMode ? UpdateCompany : CreateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanies');
      navigate('/companies');
    },
  });

  const fields = [
    { name: 'name', label: 'Company Name', type: 'text', required: true },
    { name: 'isActive', label: 'Is Active', type: 'checkbox' },
    { name: 'comment', label: 'Comment', type: 'textarea' },
    { name: 'location', label: 'Location', type: 'text' },
  ];

  const handleSubmit = (formData) => {
    const payload = isEditMode ? { ...formData, id } : formData;
    mutation.mutate(payload);
  };

  const handleCancel = () => navigate('/companies');

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | {isEditMode ? 'Edit' : 'Create'} Company</title>
      </Helmet>
      <TopNav imageSrc={company} text={isEditMode ? 'Edit Company' : 'Create Company'} />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <h1 className="h3 mb-4 text-gray-800">{isEditMode ? 'Edit' : 'Create'} Company</h1>
          <FormBuilder
            fields={fields}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel={isEditMode ? 'Update Company' : 'Create Company'}
            cancelLabel="Cancel"
            initialValues={initialValues}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompanyForm;