import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Helmet } from "react-helmet";
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import ListPage from '../components/ListPage';
import { GetResults, GenerateCSVResult, GetDropDown } from '../services';
import { EntityList } from '../components/EntityList';
import { renderDateTime } from '../util/convertDate';
import medicallaboratory from '../img/medicallaboratory.png';
import { generateLink } from '../util/linkHelper';
import { useQuery } from 'react-query';

const ResultList = ({ data, onSort, onFilter, sortField, sortAscending, filters, filterOptions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const columns = [
    { field: 'bacteriaTypeName', header: 'Test Type', sortable: true},
    { field: 'waterTypeName', header: 'Water Type', sortable: true },
    { field: 'statusName', header: 'Status', sortable: false },
    { field: 'device.name', header: 'Device', sortable: false, render: (value, row) => generateLink('device', row.device.id, row.device.name, 'fa-desktop')},
    { field: 'sample.collectionsSite', header: 'Collection Site', sortable: false },
    { field: 'aiPredictions.concentration', header: 'Count (CFU/100 ml)', sortable: false },
    { field: 'slide', header: 'Slide Number', sortable: true },    
    {
      field: 'createDateTimeLocal',
      header: 'Test Date',
      render: renderDateTime,
      sortable: false
    }
  ];

  const handleDetails = (cloudResultId) => {
    navigate(`/test-records/${cloudResultId}?${searchParams.toString()}`);
  };

  const actions = [
    { label: 'Details', onClick: handleDetails, color: 'primary' },
  ];

  return (
    <EntityList
      entityName="results"
      entities={data?.results || []}
      columns={columns}
      actions={actions}
      onSort={onSort}
      onFilter={onFilter}
      sortField={sortField}
      sortAscending={sortAscending}
      filters={filters}
      filterOptions={filterOptions}
    />
  );
};

const TestRecordListView = () => {
  const { data: companies } = useQuery("getCompanies", GetDropDown);

  const handleDownloadCSV = async () => {
    await GenerateCSVResult();
  };

  const filterOptions = [
    { field: 'bacteriaTypeName', label: 'Test Type', type: 'select', options: [
      { value: 'E.coli', label: 'E.coli' },
      { value: 'Total Coliform', label: 'Coliform' },
    ]},
    { field: 'waterTypeName', label: 'Water Type', type: 'select', options: [
      { value: 'Drinking', label: 'Drinking' },
      { value: 'Other', label: 'Other' },
      { value: 'NA', label: 'Unknow' },
    ]},
    //slide number
    { field: 'slide', label: 'Slide Number', type: 'text' },   
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Test Records</title>
      </Helmet>
      <TopNav imageSrc={medicallaboratory} text="Test Records" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3 mb-0 text-gray-800">Test Records</h1>
            <Button color="primary" onClick={handleDownloadCSV}>
              <i className="fas fa-download fa-sm text-white-50 mr-2"></i>
              Download CSV
            </Button>
          </div>
          <ListPage
            fetchDataFunction={GetResults}
            ListComponent={ResultList}
            filterOptions={filterOptions}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TestRecordListView;