// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './profileSlice';
import timeZoneReducer from './timeZoneSlice';

export const store = configureStore({
    reducer: {
        profile: profileReducer,
        timeZones: timeZoneReducer,
    },
});