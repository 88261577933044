import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from './Pagination';
import RoshanLoader from './RoshanLoader';
import { Button, Badge } from 'reactstrap';

const ListPage = ({
  fetchDataFunction,
  ListComponent,
  filterOptions,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') || '1', 10));
  const [sortField, setSortField] = useState(searchParams.get('sortField') || '');
  const [sortAscending, setSortAscending] = useState(searchParams.get('sortAscending') !== 'false');
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const initialFilters = {};
    filterOptions.forEach(option => {
      const value = searchParams.get(`filter_${option.field}`);
      if (value) {
        initialFilters[option.field] = value;
      }
    });
    setFilters(initialFilters);
  }, []);

  const { data, error, isLoading, refetch } = useQuery(
    ['getData', currentPage, sortField, sortAscending, filters],
    () => fetchDataFunction(currentPage, sortField, sortAscending, filters),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', currentPage.toString());
    if (sortField) params.set('sortField', sortField);
    params.set('sortAscending', sortAscending.toString());
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        params.set(`filter_${key}`, value);
      }
    });
    setSearchParams(params);
  }, [currentPage, sortField, sortAscending, filters, setSearchParams]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortAscending(!sortAscending);
    } else {
      setSortField(field);
      setSortAscending(true);
    }
  };

  const handleFilter = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
    setCurrentPage(1);
    refetch();
  };

  const handleClearFilter = (field) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[field];
      return newFilters;
    });
    setCurrentPage(1);
    refetch();
  };

  const handleClearAllFilters = () => {
    setFilters({});
    setCurrentPage(1);
    const params = new URLSearchParams(searchParams);
    filterOptions.forEach(option => {
      params.delete(`filter_${option.field}`);
    });
    setSearchParams(params);
    refetch();
  };

  if (error) return <div>Error: {error.message}</div>;
  if (isLoading) return <RoshanLoader />;

  return (
    <>
      <div className="mb-3">
        {Object.entries(filters).map(([field, value]) => (
          <Badge key={field} color="primary" className="mr-2 p-2">
            {field}: {value}
            <Button close onClick={() => handleClearFilter(field)} />
          </Badge>
        ))}
        {Object.keys(filters).length > 0 && (
          <Button color="secondary" size="sm" onClick={handleClearAllFilters}>
            Clear All Filters
          </Button>
        )}
      </div>
      <ListComponent
        data={data}
        onSort={handleSort}
        onFilter={handleFilter}
        sortField={sortField}
        sortAscending={sortAscending}
        filters={filters}
        filterOptions={filterOptions}
      />
      {data && data.totalPage && (
        <Pagination
          onPageChange={handlePageChange}
          pageCount={data.totalPage}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default ListPage;