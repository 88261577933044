import React from "react";
import { Pagination as RSPagination, PaginationItem, PaginationLink } from "reactstrap";
import _ from "lodash";

export const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  const offSet = 10;

  if (pageCount <= 1) return null;
  let pages = _.range(1, pageCount + 1);

  pages =
    pages.length > offSet
      ? _.range(
        currentPage,
        offSet + currentPage < pageCount + 1
          ? offSet + currentPage
          : pageCount + 1
      )
      : _.range(1, pageCount + 1);

  return (
    <RSPagination size="sm" className="pagination justify-content-center">
      <PaginationItem>
        <PaginationLink
          first
          onClick={() => {
            onPageChange(1);
          }}
        />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          previous
          onClick={() => {
            if (currentPage > 1) {
              onPageChange(currentPage - 1);
            }
          }}
        />
      </PaginationItem>
      {pages.map((page, index) => (
        <PaginationItem
          key={index + 1000}
          active={page === currentPage ? true : false}
        >
          <PaginationLink
            key={index + page + 5000}
            onClick={() => {
              onPageChange(page);
            }}
          >
            {page < pageCount && index === 9 ? (
              <div className="d-flex ">
                {page} <div className="ms-1">...</div>
              </div>
            ) : (
              page
            )}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem>
        <PaginationLink
          next
          onClick={() => {
            if (currentPage < pageCount) {
              onPageChange(currentPage + 1);
            }
          }}
        />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink last onClick={() => onPageChange(pageCount)} />
      </PaginationItem>
    </RSPagination>
  );
};