import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import TopNav from "../components/TopNav";
import SideNav from "../components/SideNav";
import Footer from "../components/footer";
// import AuthContext from "../context/authContext";
import useLocalStorage from "../components/hoc/localStorage";
import { useQuery } from "react-query";
import { GetQRCode, GetDashboard } from "../services";
import RoshanLoader from "../components/RoshanLoader";
import { Col, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import dashboardicon from "../img/dashboardicon.png";

const DashboardPage = () => {
  const { data, isLoading } = useQuery(["getDashboard"], () => GetDashboard(), {
    refetchOnWindowFocus: true,
  });

  const [localFullName, setLocalFullName] = useLocalStorage("fullName", "");
  const [localRole, setLocalRole] = useLocalStorage("role", "user");
  useEffect(() => {
    if (data?.fullName) {
      setLocalFullName(data?.fullName);
      setLocalRole(data?.userRole);
    }
  }, [data?.fullName, setLocalFullName, data?.userRole, setLocalRole]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        // display: false,
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const chartData = {
    labels,
    datasets: [
      {
        label: "Year: 2023",
        data: [
          data?.resultsGraphData?.January,
          data?.resultsGraphData?.February,
          data?.resultsGraphData?.March,
          data?.resultsGraphData?.April,
          data?.resultsGraphData?.May,
          data?.resultsGraphData?.June,
          data?.resultsGraphData?.July,
          data?.resultsGraphData?.August,
          data?.resultsGraphData?.September,
          data?.resultsGraphData?.October,
          data?.resultsGraphData?.November,
          data?.resultsGraphData?.December,
        ],
        backgroundColor: ["rgba(35, 37, 98, 0.9)"],
        borderColor: ["rgba(59, 37, 98, 0.9)"],
        borderWidth: 1,
      },
    ],
  };
  const qrText = data?.mobileQrCode;

  const { data: qrCode, isLoading: qrIsLoading } = useQuery(
    ["getQRCode", qrText],
    () => GetQRCode(qrText),
    {
      enabled: !!qrText,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div className="maincontent">
        <div className="d-flex justify-content-center">
          <RoshanLoader />
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Dashboard</title>
      </Helmet>
      <TopNav
        fullName={data?.fullName}
        imageSrc={dashboardicon}
        text="Dashboard"
      />
      <SideNav fullName={data?.fullName} role={data?.userRole} />
      <div className="maincontent">
        <div id="wrapper">
          <div className="container-fluid main-content">
            <div className="row">
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Registered Devices
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {data?.registeredDeviceCount}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa-solid fa-desktop fa-2x text-gray-800"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                          Registered Users
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {data?.registeredUserCount}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa-solid fa-user-group fa-2x text-success"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                          Absent Results
                        </div>
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto">
                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                              {data?.absentResultsCount}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa-solid fa-droplet fa-2x text-info"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-danger shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                          Present Results
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {data?.presentResultsCount}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fa-solid fa-triangle-exclamation fa-2x text-danger"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col md={4} className=" ">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-gray-800">
                      Mobile Authentication
                    </h6>
                  </div>
                  <div className="card-body testsperformed">
                    <div className="d-flex justify-content-center">
                      {!qrIsLoading ? (
                        <>
                          <img
                            className="barcode "
                            src={qrCode}
                            alt="Barcode img"
                          />
                        </>
                      ) : (
                        <>
                          <RoshanLoader />
                        </>
                      )}
                    </div>
                    <hr />
                    <span className="text-black">
                      <b>Token: {data?.mobileToken}</b>
                    </span>
                    <br />{" "}
                    <span className="text-black">
                      {" "}
                      It is useable inside your mobile application.
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-gray-800">
                      Number of performed tests
                    </h6>
                    <div className="dropdown no-arrow">
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                        aria-labelledby="dropdownMenuLink"
                      ></div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <Bar options={options} data={chartData} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashboardPage;
