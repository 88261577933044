import React, { useState, useEffect, useContext } from "react";
import logo from "../img/home-logo.png";
import velocenslogo from "../img/velocens-white@3x.png";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { LoginUser } from "../services";
import AuthContext from "../context/authContext";
import useLocalStorage from "../components/hoc/localStorage";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [emailFlag, setEmailFlag] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordFlag, setPasswordFlag] = useState(true);
  const [clickedFlag, setClickedFlag] = useState(false);
  const [loginErrorFlag, setLoginErrorFlag] = useState(false);
  const { token } = useContext(AuthContext);
  const [localToken, setLocalToken] = useLocalStorage("localToken", "");
  const navigate = useNavigate();

  useEffect(() => {
    if (localToken && localToken.length > 1) navigate("/dashboard");
  }, [localToken, token, navigate]);

  const handleClick = async (e, setJWT) => {
    setClickedFlag(true);
    e.preventDefault();
    try {
      const response = await LoginUser(email, password);
      if (response.length > 1) {
        setJWT(response);
        setLocalToken(response);
        window.location.assign("/dashboard");
      } else setLoginErrorFlag(true);
    } catch (error) { }
  };

  const handleChange = ({ id, value }) => {
    switch (id) {
      case "email":
        if (value.length < 5) {
          setEmail(value);
          setEmailFlag(true);
          setLoginErrorFlag(false);
          setClickedFlag(false);
        } else {
          setEmail(value);
          setEmailFlag(false);
          setLoginErrorFlag(false);
          setClickedFlag(false);
        }
        break;
      case "password":
        if (value.length < 3) {
          setPassword(value);
          setPasswordFlag(true);
          setClickedFlag(false);
          setLoginErrorFlag(false);
        } else {
          setPassword(value);
          setPasswordFlag(false);
          setClickedFlag(false);
          setLoginErrorFlag(false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <AuthContext.Consumer>
      {({ token, setJWT }) => (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Roshan Water | Login</title>
          </Helmet>
          <div></div>
          <div className="row background-main-singlecolor loginpage">
            <div className="loadingio-spinner-fluid-br2vzx53rm8 left-motion">
              <div className="ldio-9u8jung592p left-motion">
                <div></div>
                <div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                  <div className="card o-hidden border-0 shadow-lg background-main-singlecolor main-card">
                    <div className="card-body p-0 ">
                      <div className="row">
                        <div className="col-lg-6 d-none d-lg-block background-main text-center">
                          <div>
                            <img
                              src={logo}
                              alt="Roshan logo"
                              className="home-logo"
                            />
                          </div>
                          <img
                            src={velocenslogo}
                            alt="Roshan logo"
                            className="home-velo-logo mt-4"
                          />
                        </div>
                        <div className="col-lg-6 background-main">
                          <div className="p-5">
                            <div className="text-center">
                              <h1 className="h4 text-white mb-4">
                                Welcome to the Portal!
                              </h1>
                            </div>
                            <form className="user form mt-5">
                              <div className="form-group ">
                                <input
                                  type="email"
                                  className="form-control home-forms"
                                  id="email"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Email Address..."
                                  onChange={(e) => handleChange(e.target)}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control home-forms"
                                  id="password"
                                  placeholder="Password"
                                  onChange={(e) => handleChange(e.target)}
                                />
                              </div>
                              <div className="form-group">
                                <div className="custom-control custom-checkbox small">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck"
                                  />
                                  <label
                                    className="custom-control-label text-white "
                                    htmlFor="customCheck"
                                  >
                                    Remember Me
                                  </label>
                                </div>
                              </div>
                              {!emailFlag &&
                                !passwordFlag &&
                                !clickedFlag &&
                                !loginErrorFlag ? (
                                <button
                                  onClick={(e) => handleClick(e, setJWT)}
                                  className="btn btn-primary btn-block home-login-forms"
                                >
                                  Login
                                </button>
                              ) : (
                                !clickedFlag && (
                                  <button
                                    className="btn btn-primary btn-block home-login-forms"
                                    disabled
                                  >
                                    Login
                                  </button>
                                )
                              )}
                              {clickedFlag && !loginErrorFlag && (
                                <button
                                  className="btn btn-primary btn-block home-login-forms"
                                  disabled
                                >
                                  Logging in...{" "}
                                  <Spinner size={"sm"}>Loading...</Spinner>
                                </button>
                              )}
                              {clickedFlag && loginErrorFlag && (
                                <>
                                  <button
                                    className="btn btn-primary btn-block home-login-forms"
                                    disabled
                                  >
                                    Login
                                  </button>
                                  <div className="p-2 m-3 alert alert-danger">
                                    wrong username or password
                                  </div>
                                </>
                              )}
                            </form>
                            <hr />
                            <div className="text-center ">
                              <Link
                                className="small text-color "
                                to="/forgot-password"
                              >
                                Forgot Password?
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AuthContext.Consumer>
  );
};

export default LoginPage;
