import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Col, Label, Row, Card, Form, FormGroup, Input, FormFeedback, Alert, Container } from "reactstrap";
import TopNav from "../components/TopNav";
import SideNav from "../components/SideNav";
import Footer from "../components/footer";
import { UpdatePassword } from "../services";
import userprofile from "../img/userprofile.png";

const schema = yup.object().shape({
    oldPassword: yup.string().required("Old password is required"),
    newPassword: yup.string().min(6, "Password must be at least 6 characters").required("New password is required"),
    repeatedPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePasswordPage = () => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const updatePasswordMutation = useMutation(UpdatePassword, {
        onSuccess: () => {
            alert("Password updated successfully");
            reset();
        },
    });

    const onSubmit = (data) => {
        updatePasswordMutation.mutate({
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
        });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Roshan Water | Change Password</title>
            </Helmet>
            <TopNav imageSrc={userprofile} text="Change Password" />
            <SideNav />

            <div className="maincontent">
                <Container fluid className="main-content">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={12}>
                                <Card className="shadow mb-4">
                                    <div className="card-header py-3">
                                        <h6 className="m-0 font-weight-bold text-gray-800">Change Password</h6>
                                    </div>
                                    <div className="card-body">
                                        <Row>
                                            <Col md={4}>
                                                <Controller
                                                    name="oldPassword"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormGroup>
                                                            <Label for="oldPassword">Old Password</Label>
                                                            <Input {...field} type="password" invalid={!!errors.oldPassword} />
                                                            {errors.oldPassword && <FormFeedback>{errors.oldPassword.message}</FormFeedback>}
                                                        </FormGroup>
                                                    )}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Controller
                                                    name="newPassword"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormGroup>
                                                            <Label for="newPassword">New Password</Label>
                                                            <Input {...field} type="password" invalid={!!errors.newPassword} />
                                                            {errors.newPassword && <FormFeedback>{errors.newPassword.message}</FormFeedback>}
                                                        </FormGroup>
                                                    )}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Controller
                                                    name="repeatedPassword"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormGroup>
                                                            <Label for="repeatedPassword">Repeat New Password</Label>
                                                            <Input {...field} type="password" invalid={!!errors.repeatedPassword} />
                                                            {errors.repeatedPassword && <FormFeedback>{errors.repeatedPassword.message}</FormFeedback>}
                                                        </FormGroup>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Button type="submit" color="primary" className="mt-2">
                                            Update Password
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default ChangePasswordPage;