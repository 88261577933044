import React from 'react';
import { Row, Col } from 'reactstrap';
import { ConvertDate, Time } from '../util/convertDate';
import MapProvider from './map';

const DetailsList = ({ data, fields }) => {
  if (!data || !fields || !Array.isArray(fields) || fields.length === 0) {
    return <div>No data to display</div>;
  }

  const renderField = (field, value) => {
    if (value === null || value === undefined) {
      return '-';
    }

    // If a custom render function is provided, use it
    if (field.render) {
      return field.render(value, data);
    }

    // If a value is directly provided (e.g., for formatted date/time), use it
    if (field.value !== undefined) {
      return field.value;
    }

    switch (field.type) {
      case 'date':
        return ConvertDate(value) || '-';
      case 'time':
        return Time(value) || '-';
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'map':
        return (
          value.latitude && value.longitude ? (
            <div id="demoMap" className="demo-map">
              <MapProvider
                lat={value.latitude}
                lng={value.longitude}
                observations={value.observations || ''}
              />
            </div>
          ) : (
            'No location data available'
          )
        );
      default:
        return value.toString() || '-';
    }
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <div className="card-body">
      <div id="accordion" className="table-responsive">
        <div className="d-flex overflow-hidden">
          <div className="flex-grow-1">
            {fields.map((fieldGroup, groupIndex) => (
              <Row key={groupIndex}>
                {Array.isArray(fieldGroup) ? fieldGroup.map((field, fieldIndex) => (
                  <Col md={4} key={fieldIndex}>
                    <div className="p-2">
                      <i className={`fa-solid ${field.icon} mr-2`}></i>
                      <b>{field.label}:</b>{' '}
                      {renderField(field, getNestedValue(data, field.key))}
                    </div>
                  </Col>
                )) : null}
              </Row>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsList;