import React from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "../img/home-logo.png";
import useLocalStorage from "./hoc/localStorage";

const NavBar = ({ fullName, role }) => {
  const [localToken, setLocalToken] = useLocalStorage("localToken", "");
  const [localFullName, setLocalFullName] = useLocalStorage("fullName", "");
  const [localRole, setLocalRole] = useLocalStorage("role", "");

  const handleLogout = () => {
    setLocalFullName("");
    setLocalRole("");
    setLocalToken("");
    window.location.assign("/login");
  };

  const isAdmin = localRole === "Administrator" || role === "Administrator";

  const menuItems = [
    { label: "Test Records", icon: "fa-square-poll-vertical", link: "/test-records" },
    { label: "Devices", icon: "fa-desktop", link: "/devices" },
    { label: "Samples", icon: "fa-vial", link: "/samples" },
    { label: "Companies", icon: "fa-building", link: "/companies", adminOnly: true },
    { label: "Users List", icon: "fa-user-group", link: "/users-list" },
    { label: "Profile", icon: "fa-id-badge", link: "/profile" },
    { label: "Change Password", icon: "fa-key", link: "/change-password" },
    { label: "Resources", icon: "fa-book-open", link: "/resources" },
  ];

  const renderMenuItem = (item) => {
    if (item.adminOnly && !isAdmin) return null;

    return (
      <li className="nav-item" key={item.link}>
        <Link className="nav-link collapsed" to={item.link}>
          <i className={`fa-solid ${item.icon}`}></i>
          <span>{item.label}</span>
        </Link>
      </li>
    );
  };

  return (
    <div className="mainside">
      <ul
        className="navbar-nav background-main sidebar sidebar-dashboard sidebar-dark accordion"
        id="accordionSidebar"
      >
        <div className="d-flex flex-column bd-highlight mb-3">
          <div>
            <a className="sidebar-brand" href="https://roshanwater.com/">
              <div className="sidebar-brand-icon">
                <img src={logo} alt="Roshan Logo" className="dashboard-logo" />
              </div>
            </a>
          </div>
          <div>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item active">
              <Link className="nav-link" to="/dashboard">
                <i className="fa-solid fa-user"></i>
                <span>{localFullName || fullName}</span>
              </Link>
            </li>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Menu</div>
            {menuItems.map(renderMenuItem)}
            <hr className="sidebar-divider my-0" />
            <li className="nav-item">
              <div className="nav-link" id="tog" onClick={handleLogout}>
                <i className="fa-solid fa-right-from-bracket"></i>
                <span>Logout</span>
              </div>
            </li>
          </div>
        </div>
      </ul>
      <Outlet />
    </div>
  );
};

export default NavBar;