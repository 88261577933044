import React, { useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { store } from './redux/store'; // Make sure this path is correct
import AuthContext from "./context/authContext";
import { RequireAuth } from "./components/hoc/requireAuth";

// Page imports
import {
  ChangePassword, Dashboard, TestRecords, Devices, Samples,
  Profile, Login, Forgot, Users, DeviceDetails,
  UserDetails,  SampleDetailsPage, Companies,
  CompanyDetails, Resources, 
  TestRecordDetails
} from "./pages";

// Style imports
import "./vendor/fontawesome-free-6.0.0-web/css/all.min.css";
import "./vendor/bootstrap/bootstrap.min.css";
import "./vendor/admin-template/admin-template.min.css";
import "./css/roshanadminpanel.css";
import DeviceForm from "./components/Forms/DeviceForm";
import UserForm from "./components/Forms/UserForm";
import CompanyForm from "./components/Forms/CompanyForm";
import GenerateQrForm from "./components/Forms/GenerateQrForm";

const ROUTES = [
  { path: "/", element: <Login />, public: true },
  { path: "/login", element: <Login />, public: true },
  { path: "/forgot-password", element: <Forgot />, public: true },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/test-records", element: <TestRecords /> },
  { path: "/test-records/:id", element: <TestRecordDetails /> },
  { path: "/devices", element: <Devices /> },
  { path: "/devices/create", element: <DeviceForm /> },
  { path: "/devices/:id", element: <DeviceDetails /> },
  { path: "/devices/:id/edit", element: <DeviceForm /> },
  
  { path: "/samples", element: <Samples /> },
  { path: "/samples/generate-qr", element: <GenerateQrForm /> },
  { path: "/samples/:id", element: <SampleDetailsPage /> },

  { path: "/companies", element: <Companies /> },
  { path: "/companies/create", element: <CompanyForm /> },
  { path: "/companies/:id", element: <CompanyDetails /> },
  { path: "/companies/:id/edit", element: <CompanyForm /> },

  { path: "/users-list", element: <Users /> },
  { path: "/users/:id", element: <UserDetails /> },
  { path: "/users/:id/edit", element: <UserForm /> },
  { path: "/users/create", element: <UserForm /> },

  { path: "/profile", element: <Profile /> },
  { path: "/change-password", element: <ChangePassword /> },
  { path: "/resources", element: <Resources /> },
];

// Create a client
const queryClient = new QueryClient();

function App() {
  const [token, setToken] = useState("");
  const setJWT = (value) => setToken(value);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={{ token, setJWT }}>
          <div className="mainpages">
            <BrowserRouter>
              <Routes>
                {ROUTES.map(({ path, element, public: isPublic }) => (
                  <Route
                    key={path}
                    path={path}
                    element={isPublic ? element : <RequireAuth>{element}</RequireAuth>}
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </div>
        </AuthContext.Provider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;