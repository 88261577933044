import React, { useState } from 'react';
import { EntityTable } from './EntityTable';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Button } from 'reactstrap';

export const EntityList = ({
    entityName,
    entities,
    columns,
    actions,
    onSort,
    onFilter,
    sortField,
    sortAscending,
    filters,
    filterOptions,
    ...props
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedFilterField, setSelectedFilterField] = useState('');
    const [filterInputValue, setFilterInputValue] = useState('');

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleFilterSelect = (field) => {
        setSelectedFilterField(field);
        setFilterInputValue(filters[field] || '');
    };

    const handleFilterApply = () => {
        if (selectedFilterField && filterInputValue) {
            onFilter(selectedFilterField, filterInputValue);
        }
        setDropdownOpen(false);
    };

    const renderFilterInput = () => {
        const filterOption = filterOptions.find(option => option.field === selectedFilterField);
        if (!filterOption) return null;

        if (filterOption.type === 'select') {
            return (
                <Input 
                    type="select" 
                    value={filterInputValue} 
                    onChange={(e) => setFilterInputValue(e.target.value)}
                >
                    <option value="">Select...</option>
                    {filterOption.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Input>
            );
        }

        return (
            <Input 
                type="text"
                value={filterInputValue}
                onChange={(e) => setFilterInputValue(e.target.value)}
                placeholder={`Filter by ${filterOption.label}`}
            />
        );
    };

    return (
        <div>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="mb-3">
                <DropdownToggle caret>
                    Add Filter
                </DropdownToggle>
                <DropdownMenu>
                    {filterOptions.map(option => (
                        <DropdownItem key={option.field} onClick={() => handleFilterSelect(option.field)}>
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            {selectedFilterField && (
                <div className="mb-3 d-flex align-items-center">
                    {renderFilterInput()}
                    <Button color="primary" onClick={handleFilterApply} className="ml-2">Apply</Button>
                </div>
            )}
            <EntityTable
                entities={entities}
                columns={columns}
                onSort={onSort}
                sortField={sortField}
                sortAscending={sortAscending}
                actions={actions}
                {...props}
            />
        </div>
    );
};