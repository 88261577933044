// src/redux/timeZoneSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetTimeZone } from '../services';

export const fetchTimeZones = createAsyncThunk(
    'timeZones/fetchTimeZones',
    async () => {
        const response = await GetTimeZone();
        return response;
    }
);

const timeZoneSlice = createSlice({
    name: 'timeZones',
    initialState: {
        data: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTimeZones.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTimeZones.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchTimeZones.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default timeZoneSlice.reducer;