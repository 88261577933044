import React from 'react';
import { GetUser } from '../services';
import DetailPage from '../components/DetailPage';
import DetailsList from '../components/DetailsList';
import users from '../img/users.png';
import { formatCompactDateTime } from '../util/convertDate';
import { generateLink } from '../util/linkHelper';

const UserDetailsPage = () => {
  const fields = [
    [
      { key: 'fullName', label: 'Full Name', icon: 'fa-user' },
      { key: 'email', label: 'Email', icon: 'fa-envelope' },
      { 
        key: 'companyName', 
        label: 'Company', 
        icon: 'fa-building',
        render: (value, row) => generateLink('company', row.companyId, value, 'fa-building')
      },
    ],
    [
      { key: 'isManager', label: 'Is Manager', icon: 'fa-user-tie', type: 'boolean' },
      { key: 'status', label: 'Status', icon: 'fa-info-circle' },
      { key: 'mobile', label: 'Mobile', icon: 'fa-mobile-alt' },
    ],
    [
      { 
        key: 'lastLogin', 
        label: 'Last Login', 
        icon: 'fa-clock',
        render: (value) => value ? formatCompactDateTime(value) : '-'
      },
      { key: 'address', label: 'Address', icon: 'fa-map-marker-alt' },
    ],
  ];

  const UserDetailsList = ({ data }) => <DetailsList data={data} fields={fields} />;

  return (
    <DetailPage
      title="User Details"
      fetchDataFunction={GetUser}
      DetailComponent={UserDetailsList}
      backUrl="/users-list"
      iconSrc={users}
    />
  );
};

export default UserDetailsPage;