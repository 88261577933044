import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Helmet } from "react-helmet";
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import ListPage from '../components/ListPage';
import { GetUsers, RegisterUser, GetDropDown } from '../services';
import { EntityList } from '../components/EntityList';
import { renderDateTime } from '../util/convertDate';
import users from '../img/users.png';
import { useQuery } from 'react-query';

const UserList = ({ data, onSort, onFilter, sortField, sortAscending, filters, filterOptions }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const columns = [
    { field: 'firstName', header: 'First Name', sortable: true, filterable: true },
    { field: 'lastName', header: 'Last Name', sortable: true, filterable: true },
    { field: 'email', header: 'Email', sortable: true, filterable: true },
    { field: 'companyName', header: 'Company Name', sortable: false, filterable: true },
    {
      field: 'lastLogin',
      header: 'Last Login',
      render: renderDateTime,
      sortable: false
    }
  ];

  const handleDetails = (userId) => {
    navigate(`/users/${userId}?${searchParams.toString()}`);
  };

  const handleEdit = (userId) => {
    navigate(`/users/${userId}/edit?${searchParams.toString()}`);
  };

  const actions = [
    { label: 'Details', onClick: handleDetails, color: 'info' },
    { label: 'Edit', onClick: handleEdit, color: 'primary' },
  ];

  return (
    <EntityList
      entityName="users"
      entities={data?.users || []}
      columns={columns}
      actions={actions}
      onSort={onSort}
      onFilter={onFilter}
      sortField={sortField}
      sortAscending={sortAscending}
      filters={filters}
      filterOptions={filterOptions}
    />
  );
};

const UserListView = () => {
  const navigate = useNavigate();
  const { data: companies } = useQuery("getCompanies", GetDropDown);

  const handleCreateUser = () => {
    navigate('/users/create');
  };

  const filterOptions = [
    { field: 'firstName', label: 'First Name', type: 'text' },
    { field: 'lastName', label: 'Last Name', type: 'text' },
    { field: 'email', label: 'Email', type: 'text' },
    { 
      field: 'companyName', 
      label: 'Company', 
      type: 'select', 
      options: companies ? Object.entries(companies).map(([value, label]) => ({ value, label })) : []
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Users</title>
      </Helmet>
      <TopNav imageSrc={users} text="Users" />
      <SideNav />
      <div className="maincontent">
        <div className="container-fluid main-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3 mb-0 text-gray-800">Users</h1>
            <Button color="primary" onClick={handleCreateUser}>
              <i className="fa-solid fa-user-plus fa-sm text-white-50 mr-2"></i>
              Register User
            </Button>
          </div>
          <ListPage
            fetchDataFunction={GetUsers}
            ListComponent={UserList}
            filterOptions={filterOptions}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserListView;