import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import logo from "../img/home-logo.png";
import velocenslogo from "../img/velocens-white@3x.png";

function ForgotPasswordPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Forgot Password</title>
      </Helmet>
      <body className="row background-main-singlecolor loginpage">
        <div className="loadingio-spinner-fluid-br2vzx53rm8 left-motion">
          <div className="ldio-9u8jung592p left-motion">
            <div></div>
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg background-main-singlecolor main-card">
                <div className="card-body p-0 ">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block background-main text-center">
                      <div>
                        <img
                          src={logo}
                          alt="Roshan logo"
                          className="home-logo"
                        />
                      </div>

                      <img
                        src={velocenslogo}
                        alt="Roshan logo"
                        className="home-velo-logo mt-4"
                      />
                    </div>
                    <div className="col-lg-6 background-main">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-white mb-4">
                            Forgot Your password?
                          </h1>
                          <p className="mb-4 text-white">
                            Please enter your email address below and we will
                            send you a link to reset your password!
                          </p>
                        </div>
                        <form className="user form mt-5">
                          <div className="form-group ">
                            <input
                              type="email"
                              className="form-control home-forms"
                              id="exampleInputEmail"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email Address..."
                            />
                          </div>

                          <Link
                            to="#"
                            className="btn btn-primary btn-block home-login-forms"
                          >
                            Reset Password!
                          </Link>
                        </form>
                        <hr />
                        <div className="text-center">
                          <Link to="/login" className="small text-color">
                            Already have an account? Login!
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default ForgotPasswordPage;
