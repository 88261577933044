// src/services/api.js
import axios from 'axios';
import { API_URL_Company } from "./config";

const api = axios.create({
    baseURL: API_URL_Company + 'api' || '/api',
});

api.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('localToken');
        if (token) {
            // Remove any surrounding quotes
            token = token.replace(/^["']|["']$/g, '');
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default api;