import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Col, Row, Card, Form, Container, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import TopNav from "../components/TopNav";
import SideNav from "../components/SideNav";
import Footer from "../components/footer";
import { fetchProfile, updateProfile, updateSetting, generateMobileToken } from '../redux/profileSlice';
import { fetchTimeZones } from '../redux/timeZoneSlice';
import userprofile from "../img/userprofile.png";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneNumber: yup.string().matches(/^\d+$/, "Invalid phone number"),
  companyName: yup.string().required("Company name is required"),
  website: yup.string().url("Invalid website URL"),
  timeZone: yup.string().required("Time zone is required"),
});

const ProfilePage = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile.data);
  const timeZones = useSelector(state => state.timeZones.data);
  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchTimeZones());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      console.log("Setting profile values:", profile);
      setValue("firstName", profile.firstName);
      setValue("lastName", profile.lastName);
      setValue("phoneNumber", profile.phoneNumber);
      setValue("companyName", profile.companyName);
      setValue("website", profile.website);
      setValue("timeZone", profile.timeZone);
      setValue("mobileToken", profile.mobileToken);
    }
  }, [profile, setValue]);

  const onSubmit = (data) => {
    console.log("Form submitted with data:", data);
    dispatch(updateProfile({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
    }));
    dispatch(updateSetting({
      companyName: data.companyName,
      website: data.website,
      timeZone: data.timeZone,
      mobileToken: data.mobileToken,
    }));
  };

  const handleGenerateToken = () => {
    dispatch(generateMobileToken());
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roshan Water | Profile</title>
      </Helmet>
      <TopNav imageSrc={userprofile} text="Profile" />
      <SideNav />

      <div className="maincontent">
        <Container fluid className="main-content">
          {!profile ? (
            <div>Loading...</div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <Card className="shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-gray-800">Personal Information</h6>
                    </div>
                    <div className="card-body">
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <FormGroup>
                            <Label for="firstName">First Name</Label>
                            <Input {...field} invalid={!!errors.firstName} />
                            {errors.firstName && <FormFeedback>{errors.firstName.message}</FormFeedback>}
                          </FormGroup>
                        )}
                      />
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <FormGroup>
                            <Label for="lastName">Last Name</Label>
                            <Input {...field} invalid={!!errors.lastName} />
                            {errors.lastName && <FormFeedback>{errors.lastName.message}</FormFeedback>}
                          </FormGroup>
                        )}
                      />
                      <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => (
                          <FormGroup>
                            <Label for="phoneNumber">Phone Number</Label>
                            <Input {...field} invalid={!!errors.phoneNumber} />
                            {errors.phoneNumber && <FormFeedback>{errors.phoneNumber.message}</FormFeedback>}
                          </FormGroup>
                        )}
                      />
                    </div>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-gray-800">Company Information</h6>
                    </div>
                    <div className="card-body">
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <FormGroup>
                            <Label for="companyName">Company Name</Label>
                            <Input {...field} invalid={!!errors.companyName} />
                            {errors.companyName && <FormFeedback>{errors.companyName.message}</FormFeedback>}
                          </FormGroup>
                        )}
                      />
                      <Controller
                        name="website"
                        control={control}
                        render={({ field }) => (
                          <FormGroup>
                            <Label for="website">Website</Label>
                            <Input {...field} invalid={!!errors.website} />
                            {errors.website && <FormFeedback>{errors.website.message}</FormFeedback>}
                          </FormGroup>
                        )}
                      />
                      <Controller
                        name="timeZone"
                        control={control}
                        render={({ field }) => (
                          <FormGroup>
                            <Label for="timeZone">Time Zone</Label>
                            <Input type="select" {...field} invalid={!!errors.timeZone}>
                              <option value="">Select Time Zone</option>
                              {timeZones && Object.entries(timeZones).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                              ))}
                            </Input>
                            {errors.timeZone && <FormFeedback>{errors.timeZone.message}</FormFeedback>}
                          </FormGroup>
                        )}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card className="shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-gray-800">Account Settings</h6>
                    </div>
                    <div className="card-body">
                      <Row>
                        <Col md={6}>
                          <Controller
                            name="mobileToken"
                            control={control}
                            render={({ field }) => (
                              <FormGroup>
                                <Label for="mobileToken">Mobile Token</Label>
                                <Input {...field} readOnly />
                              </FormGroup>
                            )}
                          />
                        </Col>
                        <Col md={6} className="mb-3 d-flex align-items-end">
                          <Button color="primary" onClick={handleGenerateToken}>
                            Generate New Token
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Button type="submit" color="primary" className="mb-4">
                Update Profile and Settings
              </Button>
            </Form>
          )}
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ProfilePage;